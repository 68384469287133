<template>
    <el-dialog class="loginDialog" :visible="registerShow" @close="closeDialog('all')" :show-close="false">
        <div class="loginContent">
            <div class="loginLeft"></div>
            <div class="loginRight">
                <div class="loginTitle">
                    <div class="titles">注册账号</div>
                    <img src="@/assets/home/close.svg" @click="closeDialog('all')" />
                </div>
                <div class="intDemo">
                    <img class="leftIcon" src="@/assets/login/usernameIcon.svg" />
                    <el-input class="usernameInt" v-model="username" placeholder="账号 (6-16位字母加数字组合)"></el-input>
                </div>

                <div class="intDemo">
                    <div class="pwdIcon">
                        <img class="leftIcon " src="@/assets/login/porswordIcon.svg" />
                    </div>
                    <el-input class="usernameInt" v-model="pwd" :type="isPwd ? 'text' : 'password'"
                        placeholder="密码 (8-16位字母加数字组合)" clearable></el-input>
                    <img class="eyes" src="@/assets/personal/closeEye.svg" v-show="isPwd" @click="isPwd = !isPwd" />
                    <img class="eyes" src="@/assets/personal/openEye.svg" v-show="!isPwd" @click="isPwd = !isPwd" />
                </div>

                <div class="intDemo">
                    <div class="pwdIcon">
                        <img class="leftIcon " src="@/assets/login/porswordIcon.svg" />
                    </div>
                    <el-input class="usernameInt" v-model="confirm_password" :type="isPwd ? 'text' : 'password'"
                        placeholder="确认密码" clearable></el-input>
                    <!-- <img class="eyes" src="@/assets/personal/closeEye.svg" v-show="isPwd" @click="isPwd = !isPwd" />
                    <img class="eyes" src="@/assets/personal/openEye.svg" v-show="!isPwd" @click="isPwd = !isPwd" /> -->
                </div>
                <div class="flex-ju">
                    <div class="flex-left" @click="setUser = !setUser">
                        <img v-show="!setUser" src="@/assets/login/round.svg" />
                        <img v-show="setUser" src="@/assets/login/round-dg.svg" />
                        我已阅读并同意 <span @click.stop="goTerams(0)">相关条款</span> 和 <span
                            @click.stop="goTerams(1)">隐私政策</span>
                    </div>

                </div>
                <van-button class="btn" :loading="subLoading" :disabled="subLoading" @click="submit">立即注册</van-button>
                <div class="already-have">已有账号？<span @click="closeDialog('loginShow')">去登录</span></div>

                <div class="encounter">
                    遇到问题？联系<img src="@/assets/login/service.svg"   @click="getService()" /><span   @click="getService()" >在线客服</span>
                </div>
            </div>
        </div>

    </el-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import {sysRegister } from '@/request/api'
export default {
    name: "registerPop",
    props: {
        show: Boolean,
    },
    data() {
        return {
            username: "",
            pwd: "",
            isPwd: false,
            setUser: false,
            subLoading: false,
            confirm_password:"",
            code:"",
        }
    },
    computed: {
    ...mapState({
      registerShow: (state) => state.popShow.registerShow,
    }),
  },
    mounted(){
    if (this.$route.query.code !== undefined) {
      this.code = this.$route.query.code
    }else {
      this.code = localStorage.getItem("myCode") ? localStorage.getItem("myCode") : '';
    }
  },
    methods: {
        ...mapActions(['setActivePop','openTerms']),
        goTerams(value) {
            var data1 = 'termsShow';
            this.openTerms({data1,value})
            // this.$emit('goTerams', value)
        },
        getService(){
                this.$store.commit("goService")
            },
        closeDialog(fieldName) {
            this.setActivePop(fieldName);
            },
        goLogin() {
            // this.$emit('goLogin');
            this.setActivePop('loginShow');
        },
        upLoginShow(newValue) {
            this.$emit('update:show', newValue);
        },
        goGoogle(index) {
            switch (index) {
                case 0:
                    window.open('https://www.google.com/chrome/', '_blank')
                    break;
                case 1:
                    window.open('https://support.apple.com/zh-cn/docs', '_blank')
                    break;
                case 2:
                    window.open('https://www.mozilla.org/en-US/', '_blank')
                    break;

                default:
                    break;
            }


        },
        submit() {
            var regPwd = /^(?=.*[0-9])(?=.*[a-zA-Z])[0-9a-zA-Z]{6,18}$/;
            if (!/^[a-zA-Z0-9]+$/.test(this.username)) {
                this.$message.error('用户名只能包含字母和数字');
                return;
            }
            if (this.confirm_password != this.pwd) {

                this.$message.error('密码和确认密码不一致');
                return;
            }
            if (!regPwd.test(this.pwd)) {
                this.$message.error('密码为6-18位且包含字母和数字');
                return;
            }
            if (this.setUser) {
                this.getInfo()
            } else {
                this.$message.error('阅读并同意相关条款和隐私政策');
            }
        },
        getInfo() {
            this.subLoading = true;
            var opt = {
                username: this.username,
                password: this.pwd,
                code: this.code == undefined || this.code == 'undefined' ? '':this.code,
                url: localStorage.getItem('inUrl') ? localStorage.getItem('inUrl') : window.location.hostname,
            }
            sysRegister(opt).then((res) => {
                if (res.code == 0) {
                    this.$message.success('注册成功');
                    this.goLogin()
                } else {
                    this.subLoading = false;
                }

            })
        },
    }
}
</script>

<style lang="less" scoped>
.loginDialog {

    /deep/.el-dialog {
        border-radius: 10px;
        width: 900px;

        .el-dialog__header {
            display: none;
        }

        .el-dialog__body {
            border-radius: 10px;
            padding: 0;
        }
    }


    .loginContent {
        border-radius: 10px;
        display: flex;
        align-items: center;

        .loginLeft {
            border-radius: 10px 0 0 10px;
            height: 577px;
            align-self: stretch;
            background: #D9D9D9 url('@/assets/login/leftImg.webp') no-repeat;
            background-size: 100% 100%;
            width: 350px;
        }

        .loginRight {
            width: 550px;
            padding: 20px;
            height: 577px;
            border-radius: 0 8px 8px 0;
            background: radial-gradient(133.65% 137.5% at -19.87% -5.42%, #02568E 6%, #13293B 61.18%, #152B3F 100%);
            position: relative;

            .loginTitle {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .titles {
                    color: #FFF;
                    font-family: "PingFang SC";
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 400;
                }

                img {
                    width: 40px;
                    height: 40px;
                    cursor: pointer;
                }
            }

            .intDemo {
                display: flex;
                align-items: center;
                border-radius: 8px;
                border: 1px solid rgba(255, 255, 255, 0.04);
                background: rgba(255, 255, 255, 0.04);
                padding: 12px;
                margin-top: 22px;

                .leftIcon {
                    width: 32px;
                    height: 32px;
                    margin-right: 10px;
                }

                .pwdIcon {
                    margin-right: 5px;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .leftIcon {
                        margin-right: 0;
                        width: 20.4px;
                        height: 24px;
                        flex-shrink: 0;
                    }

                }

                .usernameInt {
                    background: transparent;

                    /deep/.el-input__inner {
                        background-color: transparent;
                        border: 0;
                        height: 24px;
                        color: #fff;
                        font-size: 16px;
                    }

                    /deep/.el-input__inner::placeholder {
                        font-size: 16px;
                        user-select: none;
                        color: rgba(255, 255, 255, 0.45);
                    }

                }

                /deep/.el-input__suffix {
                    top: -6px;
                }

                .eyes {
                    width: 16.667px;
                    height: 14.167px;
                    flex-shrink: 0;
                }
            }

            .flex-ju {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
                margin: 14px auto 34px auto;
                user-select: none;

                .flex-left {
                    display: flex;
                    align-items: center;
                    color: rgba(255, 255, 255, 0.65);
                    cursor: pointer;
                    /* 内容/M-大多数文字 */
                    font-family: "PingFang SC";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;

                    img {
                        width: 20px;
                        height: 20px;
                        margin-right: 5px;
                    }

                    span {
                        color: #FFF;
                        font-family: "PingFang SC";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 150%;
                        margin: 0 6px;
                    }
                }

                .flex-right {
                    color: rgba(255, 255, 255, 0.65);
                    cursor: pointer;
                    /* 内容/M-大多数文字 */
                    font-family: "PingFang SC";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;

                }
            }

            .btn {
                width: 100%;
                border: 0;
                display: flex;
                height: 52px;
                padding: 4px 12px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                align-self: stretch;
                border-radius: 8px;
                background: linear-gradient(92deg, #0385FF 0%, #0359FF 100%);
                color: #FFF;
                text-align: center;
                font-family: "PingFang SC";
                font-size: 18px;
                font-style: normal;
                font-weight: 400;

            }

            .register {
                border-radius: 8px;
                background: rgba(255, 255, 255, 0.09);
                margin: 24px 0 14px 0;
            }

            .already-have {
                color: rgba(255, 255, 255, 0.65);
                text-align: center;
                font-family: "PingFang SC";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                margin-top: 34px;
                user-select: none;
                span {
                    color: #0385FF;
                    font-family: "PingFang SC";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%;
                    cursor: pointer;

                }
            }

            .encounter {
                display: flex;
                align-items: center;
                white-space: nowrap;
                justify-content: center;
                // margin-top: 14px;
                position: absolute;
                bottom: 20px;
                left: 0;
                right: 0;
                margin: auto;
                user-select: none;
                img {
                    margin: 0 5px 0 10px;
                    cursor: pointer;
                user-select: none;
                }

                span {
                    font-family: "PingFang SC";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    background: linear-gradient(180deg, #FFF -54.54%, #C4DEFF 100%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    cursor: pointer;
                user-select: none;
                }
            }

        }
    }
}
</style>