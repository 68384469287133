import { render, staticRenderFns } from "./games.vue?vue&type=template&id=4de349b4&scoped=true"
import script from "./games.vue?vue&type=script&lang=js"
export * from "./games.vue?vue&type=script&lang=js"
import style0 from "./games.vue?vue&type=style&index=0&id=4de349b4&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4de349b4",
  null
  
)

export default component.exports