<template>
  <div class="rightHead">
    <div class="headItself" >
      <div class="left">
        <img src="@/assets/head/logo.webp" />
      </div>
      <div class="right">
        <div class="noLogin"  v-show="!isLogin">
          <van-button class="registBtn" @click="openPop('registerShow')"
            >注册</van-button>
          <van-button class="loginBtn" @click="openPop('loginShow')"
            >登录</van-button>
        </div>
        <div class="balanceDemo"  v-show="isLogin">
            <div class="balanceLeft">
                <div class="balanYa">
                    <img src="@/assets/newHead/ya.webp" />
                </div>
                <div class="moneys">
                    {{ balanceOne }}<span class="hlw">{{ balanceTwo }}</span>
                </div>
                <img class="reload" src="@/assets/postWith/blueSx.svg" @click="init()" :class="{
                rotate:
                 getUserLoading
              }"/>
            </div>
            <van-button class="deposBtn" @click="openPop('depositShow')"
            >存款</van-button>
        </div>
        <div class="service" @click="$store.commit(`goService`)">
          <img src="@/assets/newHead/homeService.webp" />
        </div>
      </div>
    </div>
    
  </div>
  
</template>

<script>
import { userInfo, 
    // syslogout, platformList,userHome
 } from "@/request/api";
// mapState,
 import {  mapActions } from 'vuex';
export default {
  name: "rightHead",
  components: {
   
  },
  data() {
    return {
      isLogin: false,
      balanceOne: 0,
      balanceTwo: ".00",
      loginShow: false,
      registerShow: false,
      recoverShow: false,
      termsShow: false,
      termsTab: 0,
      getUserLoading:false,
      userData:{},
      depositShow:false,
    };
  },
  
  created(){
    
  },
   watch: {
    $route(to,from) {

      if(to.path != from){
        this.isLogin = localStorage.getItem("token");
      }
    },
  },
  mounted() {

    this.isLogin = localStorage.getItem("token");
    if (this.isLogin) {
      this.getUserInfo();
    //   this.getUserHome();
    }
  },
  methods: {
    ...mapActions(['setActivePop']),
    openPop(name){
      this.setActivePop(name);
    },
    breakRegister() {
      this.registerShow = true;
    },
    goTerams(value) {
      this.registerShow = false;
      this.recoverShow = false;
      this.loginShow = false;
      this.termsTab = value;
      this.termsShow = true;
    },
    goLogin() {
      this.registerShow = false;
      this.recoverShow = false;
      this.termsShow = false;
      this.loginShow = true;
    },
    forgetPwd() {
      this.recoverShow = false;
      this.loginShow = false;
      this.termsShow = false;
      this.recoverShow = true;
    },
    goRigister() {
      this.loginShow = false;
      this.termsShow = false;
      this.recoverShow = false;
      this.registerShow = true;
    },
    init() {
      if(this.getUserLoading){
        return;
      }
      this.getUserInfo();
    },
    getUserInfo() {
      this.getUserLoading = true; 
      userInfo().then((res) => {
        if (res.code == 0) {
          var balan = res.data.balance.toFixed(2);
          var dotIndex = balan.indexOf(".");
          if (dotIndex == -1) {
            res.data.balance = `${res.data.balance}.00`;
          }
          this.balanceOne = balan.slice(0, dotIndex + 1);
          this.balanceTwo = balan.slice(dotIndex + 1);
          this.userData = res.data;
        }
        this.getUserLoading = false;
      });
    },
  
  },
  beforeDestroy() {
    // Cleanup: Remove the event listener when component is destroyed
   
  },
};
</script>

<style lang="less" scoped>
.rightHead {
  width: 100%;
  height: 60px;
  position: relative;
  transition: all 0.5s ease;
  .headItself {
    width: 100%;
    max-width: 1440px;
    height: 60px;
    padding: 0 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    margin: auto;
    position: relative;
    z-index: 100;
    background: rgba(26, 44, 56, 1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transition: all 0.5s ease;
    .left {
      width: 98px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 20px;
      .noLogin {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
        .loginBtn {
          width: 78px;
          height: 40px;
          border: 0;
          // padding: 7px 24px;
          border-radius: 4px;
          background: linear-gradient(
            91.96deg,
            rgba(3, 133, 255, 1) 0%,
            rgba(3, 89, 255, 1) 136.81%
          );
          color: rgba(255, 255, 255, 1);
          font-family: "PingFang SC";
          font-weight: 400;
          font-size: 14px;
        }
        .registBtn {
          width: 78px;
          height: 40px;
          border-radius: 4px;
          border: 0;
          color: rgba(255, 255, 255, 1);
          background: transparent;
          font-family: "PingFang SC";
          font-weight: 400;
          font-size: 14px;
        }
      }
      .balanceDemo{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .balanceLeft{
            border-radius: 6px 0 0 6px;
            height: 46px;
            padding: 5px 12px;
            background: rgba(15, 33, 46, 1);
            display: flex;
            align-items: center;
            justify-content: center;
            .balanYa{
                width: 18px;
                height: 18px;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .moneys{
                margin-left: 5px;
                color: rgba(255, 255, 255, 1);
                font-family: DIN;
                font-weight: 700;
                font-size: 16px;
                .hlw{
                    color: rgba(255, 255, 255, 0.45);
                }
            }
            .reload{
              width: 16px;
              height: 16px;
              margin-left: 3px;
            }
        }
        .deposBtn{
            width: 53px;
            height: 46px;
            border: 0;
            border-radius:0 6px 6px 0;
            white-space: nowrap;
            background: linear-gradient(91.96deg, rgba(3, 133, 255, 1) 0%, rgba(3, 89, 255, 1) 136.81%);
            color: rgba(255, 255, 255, 1);
  font-family: "PingFang SC";
  font-weight: 400;
  font-size: 14px;
        }
      }
      .service {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.reload {
    user-select: none;
            cursor: pointer;
    &.rotate {
        animation: spin 1s linear infinite;
    }
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
</style>
