import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import {Button,Tab,Tabs,Field,Switch,PullRefresh,List }  from 'vant';
import 'vant/lib/index.css';
import '@/util/rem'
import { Lazyload } from 'vant';

Vue.use(Lazyload);
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(Button);
Vue.use(Tab); 
Vue.use(Tabs); 
Vue.use(Field);
Vue.use(Switch);
Vue.use(PullRefresh );
Vue.use(List );

if (process.env.NODE_ENV === 'production') {
  // 重写 console 的方法
  console.log = console.warn = console.error = function() {};
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
