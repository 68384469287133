import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    popShow: {
      loginShow: false,
      registerShow: false,//注册
      recoverShow: false,
      termsShow:false,
      depositShow:false,
      withdrawalShow:false,
      convertShow:false,
      outLoginShow:false,
      depositContentShow:false,
    },
    termsTab:0,
    showMessage: false,
    loadingShow: false,
    url: "",
		gamelist:[],
    isExpand:localStorage.getItem('isExpand') === 'true',
    usdData:{},
  },
  getters: {},
  mutations: {
		setGamelist(state, list) {
			state.gamelist = list
		},
    setLoadingShowTrue(state) {
      state.loadingShow = true;
    },
    setLoadingShowFalse(state) {
      state.loadingShow = false;
    },
    setLoginShow(state,value){
      state.loginShow = value;
    },
    setLoginShowTrue(state){
      state.loginShow = true;
    },
    setRegisterShow(state,value){
      state.registerShow = value;
    },
    setRecoverShow(state,value){
      state.recoverShow = value;
    },
    setUrl(state, url) {
      state.url = url;
    },
    getUrl(state) {
      return state.url;
    },

    getIsExpand(state){
      return state.isExpand;
    },
    setIsExpand(state){
      state.isExpand  = !state.isExpand;
      localStorage.setItem('isExpand',state.isExpand);
    },
    setOutLoginTrue(state){
      state.outLoginShow = true;
    },
    setOutLoginFalse(state){
      state.outLoginShow = false;
    },
    goService(state){
      state.loadingShow = true;
      setTimeout(()=>{
        state.loadingShow = false;
      },3000)
      window.open("https://secure.livechatinc.com/licence/18398763/open_chat.cgi",'_blank')
    },
    setusdData(state,data){
      state.usdData = data;
    },
    setTermsTab(state,value){
      state.termsTab = value;
    },
    setTermsShow(state,value){
      state.popShow.termsShow = value
    },
    // 弹窗控制器，开启一个弹窗之前会关闭其他弹窗
    SET_ACTIVE_PopShow(state, fieldName) {
      // 检查 list 是否是对象
      if (typeof state.popShow === 'object' && state.popShow !== null) {
        if (fieldName === 'all') {
          // 将所有字段设置为 false
          Object.keys(state.popShow).forEach(key => {
            state.popShow[key] = false;
          });
        } else if (typeof fieldName === 'string' && Object.prototype.hasOwnProperty.call(state.popShow, fieldName)) {
          
          // 将所有字段设置为 false
          // Object.keys(state.popShow).forEach(key => {
          //   state.popShow[key] = false;
          // });
         
          // let _state = state
          // Object.keys(_state.popShow).forEach(key => {
          //   console.log(key === fieldName,fieldName,key)
          //   _state.popShow[key] = (key === fieldName);
          //   console.log("第二行",_state.popShow[key])
          // });
          // console.log(this)
          state.popShow[fieldName] = true;
          setTimeout(() => {
            if(state.popShow.loginShow && fieldName!='loginShow'){
              state.popShow.loginShow = false
            }
            if(state.popShow.registerShow && fieldName!='registerShow'){

              state.popShow.registerShow = false
            }
            if(state.popShow.recoverShow && fieldName!='recoverShow'){
              state.popShow.recoverShow = false
            }
            if(state.popShow.termsShow && fieldName!='termsShow'){
              state.popShow.termsShow = false
            }
            if(state.popShow.depositShow && fieldName!='depositShow'){
              state.popShow.depositShow = false
            }
            if(state.popShow.depositContentShow && fieldName!='depositContentShow'){
              state.usdData = {};
              state.popShow.depositContentShow = false
            }
            
          }, 500);
          // 将指定字段设置为 true
          // setTimeout(() => {
          //   state.popShow[fieldName] = true;
          // console.log(state.popShow[fieldName],fieldName,state.popShow.recoverShow)
          // }, 500);
          
        } else {
          console.error('Invalid fieldName:', fieldName);
        }
      } else {
        console.error('state.popShow is not an object');
      }
    },
    openPop(state,fieldName){
      if(fieldName != 'loginShow'){
        state.popShow.loginShow = false;
      }
      
      if(fieldName != 'registerShow'){
        state.popShow.registerShow = false;
      }
      
      if(fieldName != 'recoverShow'){
        state.popShow.recoverShow = false;
      }
      
      if(fieldName != 'termsShow'){
        state.popShow.termsShow = false;
      }
      
      if(fieldName != 'depositShow'){
        state.popShow.depositShow = false;
      }
      if(fieldName != 'depositContentShow'){
        state.usdData = {};
        state.popShow.depositContentShow = false;
      }
      
      state.popShow[fieldName] = true;
      console.log(state.popShow[fieldName])
    },
 
  },

  actions: {
    openTerms({ commit },fieldName){
      commit('setTermsTab',fieldName.value)
      commit('setTermsShow',true)
      // commit('SET_ACTIVE_PopShow',fieldName)
    },
    setActivePop({ commit }, fieldName) {
      console.log("action里的",fieldName!='registerShow')
      commit('SET_ACTIVE_PopShow', fieldName);
      // commit('openPop',fieldName);
      // if(fieldName!='all')
      // commit('setPopListStatus',fieldName)
    },
    setOutLogin({ commit },fieldName){
      console.log("关闭退出",fieldName)
      if(fieldName){
        commit('setOutLoginTrue',true)
      }else{
        commit('setOutLoginFalse',false)
      }
      
      // commit('SET_ACTIVE_PopShow',fieldName)
    },

  },
  modules: {},
});
